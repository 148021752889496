<template>
  <div class="we-select__container" v-click-outside="onClose">
    <div
      v-if="formControl"
      v-bind:class="['we-select', small ? 'we-select--sm' : '']"
      v-on:click="dropOpen = !dropOpen"
      v-tooltip="getTooltip"
    >
      <!-- Selected Value Label -->
      <div class="row align-items-center we-select__selected" v-if="value">
        <div class="col pr-0 text-truncate">{{ getSelectedLabel }}</div>
        <div v-bind:class="getDropdownIcon">
          <i class="fas fa-chevron-down"></i>
        </div>
      </div>
      <!-- Selected Value Label -->
      <div class="we-select__placeholder" v-else>{{ placeholder }}</div>
    </div>
    <div
      v-else
      class="
        cursor-pointer
        text-truncate
        d-flex
        border-bottom border-warning
        pb-1
        no-select
      "
      v-on:click="dropOpen = !dropOpen"
    >
      {{ getSelectedLabel }}
    </div>
    <transition appear name="drop-transition" mode="in-out">
      <div
        class="we-select__dropdown-container custom-scrollbar shadow"
        v-show="dropOpen"
      >
        <ul class="we-select__dropdown-list" v-if="options.length">
          <li
            class="we-select__dropdown-list-item text-truncate"
            v-for="(option, i) in options"
            v-bind:key="i"
            v-on:click="onSelect(i)"
          >
            {{ option[labelProp] }}
          </li>
        </ul>
        <div v-else class="text-center">
          <i class="font-12">{{ $t("noOptions") }}</i>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  name: "WeSelect",
  props: {
    value: {
      default: null,
    },
    options: {
      default: () => [],
    },
    labelProp: {
      default: "name",
    },
    uniqueProp: {
      default: "id",
    },
    placeholder: {
      default: null,
    },
    small: {
      default: false,
    },
    formControl: {
      default: true,
    },
  },
  model: {
    prop: "value",
  },
  data() {
    return {
      dropOpen: false,
    };
  },
  methods: {
    onClose() {
      this.dropOpen = false;
    },
    onSelect(index) {
      const newValue = helper.clone(this.options[index]);

      if (this.value[this.uniqueProp] !== newValue[this.uniqueProp]) {
        this.$emit("input", newValue);
      }

      this.onClose();
    },
  },
  computed: {
    getDropdownIcon() {
      let result = "col-auto we-select__dropdown-icon";

      if (this.dropOpen) {
        result += "--open";
      }

      return result;
    },
    getSelectedLabel() {
      let result = null;

      if (this.value && this.value.hasOwnProperty(this.labelProp)) {
        result = this.value[this.labelProp];
      } else {
        result = this.value;
      }

      return result;
    },
    getTooltip() {
      if (this.getSelectedLabel && this.getSelectedLabel.length >= 10) {
        return {
          content: this.getSelectedLabel,
          placement: "bottom",
        };
      }
    },
  },
};
</script>
<style lang="scss">
.we-select__container {
  display: flex;
  position: relative;
}
.we-select {
  cursor: pointer;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.we-select--sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  height: calc(1.5em + 0.5rem + 2px);
}
.we-select__selected {
  font-weight: 500;
  user-select: none;
  position: relative;
}
.we-select__placeholder {
  user-select: none;
  color: rgb(207, 207, 207);
}
.we-select__dropdown-icon {
  transition: 0.3s all;
  transform: scaleY(1);
  right: 0;
}
.we-select__dropdown-icon--open {
  transition: 0.3s all;
  transform: scaleY(-1);
}
.we-select__dropdown-container {
  width: auto;
  max-height: 200px;
  overflow: auto;
  background-color: #fff;
  position: absolute;
  z-index: 1090;
  left: 0;
  top: 100%;
  transition: 0.3s height;
  border-radius: 0.25rem;
  padding: 0.5rem;
  border: 1px solid #ced4da;
}
.we-select__dropdown-list {
  max-height: 100%;
  overflow: hidden;
  padding: 0;
  margin: 0;
}
.we-select__dropdown-list-item {
  padding: 0.375rem;
  border-radius: 0.25rem;
  transition: 0.3s all;
  cursor: pointer;
  &:hover {
    background-color: rgb(241, 241, 241);
  }
}
.drop-transition-enter,
.drop-transition-leave-to {
  transform: scale(0.9);
  opacity: 0;
}

.drop-transition-enter-active,
.drop-transition-leave-active {
  transition: transform 100ms ease-in-out, opacity 250ms ease-in-out;
}
</style>